<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default{
  mounted(){
    this.$store.dispatch('setInitialData');
  }
}
</script>

<style lang="scss">
@import "assets/sass/style";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
