<template>
  <div>
    <b-navbar toggleable="lg" type="fortaleza" class="py-3">
      <b-container>
        <b-navbar-brand to="/">
          <b-img :src="require('@/assets/images/footer/black-logo.svg')"></b-img>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-for="(menu, key) in menuItems" :key="'menu' + key" :to="menu.text != 'Mi cuenta' ? {name: menu.value} : null" :href="menu.text == 'Mi cuenta' ? menu.value : null" class="px-3 fortaleza-nav-item">{{menu.text}}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <b-nav-text>
          <b-link class="pl-5 fortaleza-nav-item" v-b-toggle.sidebar-menu><font-awesome-icon :icon="['fal', 'bars']" /> Menú</b-link>
        </b-nav-text>
      </b-container>
    </b-navbar>
    <b-sidebar id="sidebar-menu" title="" shadow right bg-variant="white">
      <b-list-group class="border-0 d-lg-none">
        <b-list-group-item class="border-0" v-for="(menu, key) in menuItems" :key="'menu' + key" :to="menu.text != 'Mi cuenta' ? {name: menu.value} : null" :href="menu.text == 'Mi cuenta' ? menu.value : null">{{menu.text}}</b-list-group-item>
      </b-list-group>
      <b-list-group class="border-0">
        <b-list-group-item class="border-0" v-for="(menu, key) in sidebarMenuItems" :key="'menu' + key" :to="{name: menu.value}">{{menu.text}}</b-list-group-item>
      </b-list-group>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  computed: {
    menuItems() {
      return this.$store.getters.getMenuItems;
    },
    sidebarMenuItems() {
      return this.$store.getters.getSidebarMenuItems;
    }
  }
}
</script>

<style scoped lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.list-group-item{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400!important;
  font-size: 18px;
  margin-top: 15px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}

.navbar-nav {
  list-style: none!important;
}

#nav {
  padding: 30px!important;

  a {
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
