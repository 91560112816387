<template>
  <div :class="[background, showImage ? 'py-5' : '']" ref="scroll">
    <b-overlay :show="loading" rounded="sm">
      <b-container>
        <b-row :class="showImage ? 'py-5' : ''">
          <b-col
            v-if="showImage"
            cols="12"
            md="12"
            xl="6"
            :class="showImage ? 'col-xl-6 col-md-6' : 'col-xl-12 col-md-12'"
            class="my-auto"
          >
            <h3 class="contact-title">Nuestros Proyectos</h3>
            <p class="mt-4 mb-5">
              Son sinónimo de calidad de vida y cuidado en los detalles con el
              objetivo de diseñar espacios que se adapten a lo que más
              necesitas. No importa si buscas departamento, casa, sitio,
              oficina, bodega o estacionamiento. ¡Te ayudamos!
            </p>
            <div
              class="background-image contact-image"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/images/contact/contact.jpg') + ')',
              }"
            ></div>
          </b-col>
          <b-col
            v-if="!submitted"
            cols="12"
            md="12"
            xl="6"
            :class="showImage ? 'col-xl-6 col-md-6' : 'col-xl-12 col-md-12'"
            class="my-auto pt-5 pt-md-5 pt-xl-0"
          >
            <div
              class="mt-4 mt-md-0 mt-xl-0"
              :class="modal ? 'contact-green-box' : 'contact-green-box-global'"
            >
              <b-img
                :class="modal ? 'd-none d-md-block d-xl-block' : null"
                style="position: absolute; left: -3px; top: 0; width: 50px"
                :src="
                  require('@/assets/images/projects/features/green-square.svg')
                "
              ></b-img>
              <h2 class="contact-title text-left">Contáctanos</h2>
              <p class="subtitle text-left mb-5">
                Déjanos tus dudas y pronto te contactaremos para ayudarte a
                encontrar una propiedad.
              </p>
              <div class="text-left mt-3">
                <label>Rut</label>
                <b-form-input
                  v-model.trim="$v.contact.rut.$model"
                  @keyup="formatRut"
                  :state="$v.contact.rut.$dirty ? !$v.contact.rut.$error : null"
                  placeholder="Rut"
                ></b-form-input>
                <p class="mt-3 text-danger" v-if="$v.contact.rut.$error">
                  * Rut requerido
                </p>
              </div>
              <div class="text-left mt-3">
                <label>Nombre</label>
                <b-form-input
                  v-model.trim="$v.contact.name.$model"
                  :state="
                    $v.contact.name.$dirty ? !$v.contact.name.$error : null
                  "
                  placeholder="Nombre"
                ></b-form-input>
                <p class="mt-3 text-danger" v-if="$v.contact.name.$error">
                  * Nombre requerido
                </p>
              </div>
              <div class="text-left mt-3">
                <label>Email</label>
                <b-form-input
                  v-model.trim="$v.contact.email.$model"
                  :state="
                    $v.contact.email.$dirty ? !$v.contact.email.$error : null
                  "
                  placeholder="Email"
                ></b-form-input>
                <p class="mt-3 text-danger" v-if="$v.contact.email.$error">
                  * Ingrese su email correctamente
                </p>
              </div>
              <div class="text-left mt-3">
                <label>Teléfono</label>
                <b-form-input
                  v-model="$v.contact.cellphone.$model"
                  :state="
                    $v.contact.cellphone.$dirty
                      ? !$v.contact.cellphone.$error
                      : null
                  "
                  placeholder="Teléfono"
                ></b-form-input>
                <p class="mt-3 text-danger" v-if="$v.contact.cellphone.$error">
                  * Ingrese su teléfono correctamente
                </p>
              </div>
              <div class="text-left mt-3" v-if="projects">
                <label>Proyecto de interés</label>
                <b-form-select
                  v-model="$v.contact.project_name.$model"
                  :options="projectsOptions"
                  :state="
                    $v.contact.project_name.$dirty
                      ? !$v.contact.project_name.$error
                      : null
                  "
                ></b-form-select>
                <p
                  class="mt-3 text-danger"
                  v-if="$v.contact.project_name.$error"
                >
                  * Ingrese su proyecto de interés
                </p>
              </div>
              <div class="text-left mt-3">
                <label>Solicitud</label>
                <b-form-input
                  v-model.trim="$v.contact.message.$model"
                  :state="
                    $v.contact.message.$dirty
                      ? !$v.contact.message.$error
                      : null
                  "
                  placeholder="Solicitud"
                ></b-form-input>
                <p class="mt-3 text-danger" v-if="$v.contact.name.$error">
                  * Solicitud requerida
                </p>
              </div>
              <div class="mt-5 send text-center">
                <b-button class="send p-3" @click="send" variant="grey-border"
                  ><p class="text-center mb-0">Enviar información</p></b-button
                >
              </div>
            </div>
          </b-col>
          <b-col
            :class="showImage ? 'col-xl-6 col-md-6' : 'col-xl-12 col-md-12'"
            v-else
            class="my-auto"
            style="
              border: 1px solid green;
              padding: 50px 75px;
              position: relative;
            "
          >
            <b-img
              style="position: absolute; left: -3px; top: 0; width: 50px"
              :src="
                require('@/assets/images/projects/features/green-square.svg')
              "
            ></b-img>
            <h2 class="contact-title text-left">Gracias por contactarnos</h2>
            <p class="subtitle text-left mb-5">
              Uno de nuestros ejecutivos se pondrá en contacto a la brevedad.
              Mientras eso sucede te invitamos a revisar nuestros proyectos en
              venta.
            </p>
            <div class="mt-5 text-left send">
              <router-link :to="'/proyectos'"
                ><b-button class="send p-3" variant="grey-border"
                  >Proyectos en venta</b-button
                ></router-link
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "Contact",
  props: {
    background: {
      type: String,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      submitted: false,
      contact: {
        rut: null,
        customer_name: "fortaleza",
        project_name: null,
        project_id: null,
        name: null,
        email: null,
        cellphone: null,
        message: null,
        source: null,
        origin: "Contacto Web",
        tracker: null,
      },
      projectsOptions: [{ value: null, text: "Seleccionar" }],
    };
  },
  validations: {
    contact: {
      rut: { required, RutValid: isRutValid, minLength: minLength(8) },
      name: { required },
      email: { required, email },
      cellphone: { required, minLength: minLength(9) },
      message: { required },
      project_name: { required },
    },
  },
  methods: {
    send() {
      this.$v.contact.$touch();
      if (!this.$v.contact.$invalid) {
        this.loading = true;
        this.axios
          .post("contacts", {
            customer_id: "fortaleza",
            id: this.contact.project_name,
            contact: this.contact,
          })
          .then((result) => {
            self.contactResponse = result.data;
            this.submitted = true;
            this.loading = false;
            this.addCopesa("5285969");
          })
          .catch(() => {
            self.loading = false;
          });
      }
    },
    digito(rut, dv) {
      let suma = 0;
      let multiplicador = 2;
      while (rut !== 0) {
        if (multiplicador === 8) {
          multiplicador = 2;
        }
        suma += (rut % 10) * multiplicador;
        rut = Math.trunc(rut / 10);
        multiplicador++;
      }
      suma = 11 - (suma % 11);
      switch (suma) {
        case 11:
          return "0" === dv;
        case 10:
          return "K" === dv.toUpperCase();
        default:
          return suma.toString() === dv;
      }
    },
    formatRut() {
      if (this.contact.rut.length > 0) {
        this.contact.rut = this.contact.rut.replace(/[^\dKk]/g, "");
        let dato = this.contact.rut;
        let largo = dato.length;
        if (largo === 1) {
          this.dv = dato;
          this.contact.rut = "";
          this.digito(this.contact.rut, this.dv);
          this.formato();
        } else if (largo > 1) {
          this.dv = dato.substring(largo - 1);
          this.contact.rut = dato.substring(largo - 1, 0);
          this.digito(this.contact.rut, this.dv);
          this.formato();
        }
      }
    },
    formato() {
      let rut = this.contact.rut
        ? new Intl.NumberFormat("es-CL").format(parseInt(this.contact.rut)) +
          "-"
        : "";
      this.contact.rut = rut + this.dv;
    },
    addCopesa(numXsp) {
      let a = String(Math.floor(Math.random() * 10000000000000000));
      new Image().src = `https://pubads.g.doubleclick.net/activity;xsp=${numXsp};ord=${a}?`;
    },
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
  },
  mounted() {
    if (this.projects != null) {
      this.projects.map((project) => {
        project.actual_state != "Realizado"
          ? this.projectsOptions.push({
              value: project.project_name,
              text: project.name,
            })
          : null;
      });
    }
    this.addCopesa("5285969");
  },
  watch: {
    projects(newValue) {
      if (newValue) {
        this.projects.map((project) => {
          project.actual_state != "Realizado"
            ? this.projectsOptions.push({
                value: project.project_name,
                text: project.name,
              })
            : null;
        });
      }
    },
  },
};
export function isRutValid(value) {
  if (value == null) return false;
  if (value.length === 0) return false;
  var input = value.replace(/[^\dKk]/g, "");
  let dato = input;
  let largo = dato.length;
  if (largo === 1) {
    let dv = dato;
    let rut = "";
    return digito(rut, dv);
  } else if (largo > 1) {
    let dv = dato.substring(largo - 1);
    let rut = dato.substring(largo - 1, 0);
    return digito(rut, dv);
  } else {
    return false;
  }

  function digito(rut, dv) {
    let suma = 0;
    let multiplicador = 2;
    while (rut !== 0) {
      if (multiplicador === 8) {
        multiplicador = 2;
      }
      suma += (rut % 10) * multiplicador;
      rut = Math.trunc(rut / 10);
      multiplicador++;
    }
    suma = 11 - (suma % 11);
    switch (suma) {
      case 11:
        return "0" === dv;
      case 10:
        return "K" === dv.toUpperCase();
      default:
        return suma.toString() === dv;
    }
  }
}
</script>

<style scoped lang="scss">
.contact-image {
  height: 350px !important;
}
.contact-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  letter-spacing: 1.1px;
  text-transform: uppercase;

  color: #212322;
}
.send {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}

.contact-green-box-global {
  border: 1px solid green;
  padding: 50px 50px;
  position: relative;
}

@media (min-width: 992px) {
  .contact-green-box {
    border: 1px solid green;
    padding: 50px 50px;
    position: relative;
  }
}
@media (max-width: 992px) {
  .contact-title {
    font-size: 24px;
  }
  .subtitle {
    font-size: 15px;
  }
}
.custom-select {
  border: 1px solid #ced4da !important;
}
</style>
