import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars as falBars } from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from "vuelidate";
import VueTelInput from 'vue-tel-input';
import Multiselect from 'vue-multiselect';
import VueCookies from "vue-cookies";

var allCountries = [['Argentina', 'ar', '54'], ['Bolivia', 'bo', '591'], ['Chile', 'cl', '56'], ['Perú', 'pe', '51'], ['Estados Unidos', 'us', '1', 0], ['Uruguay', 'uy', '598']];
var allCountries$1 = allCountries.map(function (country) {
	return {
		name: country[0],
		iso2: country[1].toUpperCase(),
		dialCode: country[2],
		priority: country[3] || 0,
		areaCodes: country[4] || null
	}
});
//FONT AWESOME
library.add(falBars);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.component('multiselect', Multiselect)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(VueTelInput, {
	allCountries: allCountries$1,
	onlyCountries: ['CL'],
	mode: 'national',
	placeholder: '',
	enabledFlags: false,
	dynamicPlaceholder: false,
	inputOptions: {
		showDialCode: false,
		enabledCountryCode: true
	},
	validCharactersOnly: true,
	enabledCountryCode: true,
	dropdownOptions: {
		disableDialCode: false
	}
})

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/* Filtro truncate */
Vue.filter('truncate', function (text, length, replace) {
	if (text.length > length) {
		return text.substring(0, length) + replace;
	} else {
		return text;
	}
});

/* Formato número */
Vue.filter('numberFormat', function(value){
	let roundedValue = Math.round(value*100)/100
	let number = roundedValue.toString().split(".")
	let numberFormated = number.length > 1 ?  number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")+"."+number[1] : number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")
	return numberFormated ? numberFormated : 0;
});

/* Moment */

const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
    moment
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
