<template>
  <div class="footer-background pt-5">
    <b-container>
      <b-row class="pb-3">
        <b-col md="12" xl="3" class="footer-logo mb-5 mb-md-0 mb-xl-0">
          <b-row>
            <b-col cols="12">
              <b-img :src="require('@/assets/images/footer/ifortaleza-logo.svg')"></b-img>
            </b-col>
            <b-col cols="12">
              <b-img class="mt-5" :src="require('@/assets/images/footer/confianza-logo.svg')"></b-img>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6" xl="4" class="text-left text-white mt-md-5 mt-xl-0">
          <div class="p-3">
            <b-row style="border:1px solid white;">
              <b-col cols="12" md="6" xl="6" v-if="categories.length > 0">
                <p v-for="(category, key) in categories.slice(0,5)" class="my-3" :key="'category' + key"><span class="mouse-click" @click="goToMenu(category.value)">{{category.text}}</span></p>
              </b-col>
              <b-col cols="12" md="6" xl="6" class="mt-auto">
                <p v-for="(category, key) in categories.slice(5,10)" class="my-3" :key="'category' + key"><span class="mouse-click" @click="category.text != 'Contáctanos' ? goToMenu(category.value) : contactModal ? $bvModal.show('modal-contact') : goTo('contact')">{{category.text}}</span></p>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="6" xl="4" offset-xl="1" class="text-white text-left mt-5 mt-md-5 mt-xl-0">
          <p class="footer-title">Contáctanos</p>
          <p v-for="(info, key) in informations" :key="'info' + key"><a :href="info.url" target="_blank"><b-img class="mr-2" :src="info.icon"></b-img>{{info.text}}</a></p>
          <p class="mt-4">Síguenos en <a v-for="(social, key) in socials" :key="'social' + key" :href="social.url" target="_blank"><b-img class="mx-2" :src="social.icon"></b-img></a></p>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col cols="12" class="text-white">
          <p class="text-center">© {{year}} I Fortaleza. Todos los derechos reservados</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    contactModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      year: 1900 + (new Date().getYear()),
      categories: [],
      informations: [
        { icon: require('@/assets/images/footer/email.svg'), url: 'mailto:contactoinmobiliaria@ifortaleza.cl', text: 'contactoinmobiliaria@ifortaleza.cl' },
        { icon: require('@/assets/images/footer/phone.svg'), url: 'tel:+56228628700', text: '+56 2 2862 8700' },
        { icon: require('@/assets/images/footer/map.svg'), url: 'https://goo.gl/maps/uJqsjocPbh4ExExj8', text: 'Oficina Central: Alonso de Córdova 5900, oficina 1201 - Las Condes, Santiago de Chile​' },
      ],
      socials: [
        { icon: require('@/assets/images/footer/facebook.svg'), url: 'https://www.facebook.com/inmobiliariafortaleza30/' },
        { icon: require('@/assets/images/footer/instagram.svg'), url: 'https://www.instagram.com/inmobiliariafortaleza' },
        { icon: require('@/assets/images/footer/linkedin.svg'), url: 'https://www.linkedin.com/company/ifortaleza/' }
      ]
    }
  },
  mounted(){
    this.categories = this.menuItems.concat(this.sidebarMenuItems);
    this.categories.push({ text:'Contáctanos' });
  },
  methods: {
    goToMenu(url){
      this.$router.push({ name: url}).catch();
    },
    goTo(refName) {
      this.$emit('goTo', refName)
    },
  },
  computed: {
    menuItems() {
      return this.$store.getters.getMenuItems;
    },
    sidebarMenuItems() {
      return this.$store.getters.getSidebarMenuItems;
    }
  }
}
</script>

<style>
</style>
