import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from "vue-axios";
import axios from "axios";

Vue.use(Vuex)
const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	params: {}
});
Vue.use(VueAxios, instance);

export default new Vuex.Store({
  state: {
    sliders: null,
    projects: null,
    header: null,
    menuItems: [
      {text: 'Proyectos en venta', value: 'ProjectList'},
      {text: 'Oportunidades', value: 'Opportunities'},
      {text: 'Mi cuenta', value: `${process.env.VUE_APP_CUSTOMER_SITE}`}
    ],
    sidebarMenuItems: [
      {text: 'Beneficios', value: 'Campaigns'},
      // {text: 'Life', value: 'Life'},
      {text: 'Blog', value: 'Blog'},
      {text: 'Nosotros', value: 'About'},
      {text: 'Proyectos realizados', value: 'AccomplishedProjects'},
      {text: 'Servicio al cliente', value: 'CustomerService'},
      {text: 'Recomienda y gana', value: 'Referrals'},
    ],
    lifeBenefits: null,
    trajectory: null,
    news: null,
    opportunities: null,
    uf: null,
    campaigns: null
  },
  mutations: {
    setProjects(state){
      instance.get('/projects').then((response) => {
        state.projects = response.data;
      })
    },
    setOpportunities(state){
      instance.get('/opportunities').then((response) => {
        state.opportunities = response.data;
      })
    },
    setSliders(state){
      instance.get('/sliders').then((response) => {
        state.sliders = response.data;
      });
    },
    setHeader(state, page){
			state.loading = true;
			instance.get("/headers?page="+page).then((response)=>{
				state.header = response.data;
				state.loading = false;
			});
		},
    setUf(state){
			instance.get("/extras/get_uf").then((response)=>{
				state.uf = response.data;
			});
		},
    setInvestBenefits(state){
      state.investBenefits = {
        benefits: [
          { icon: require('@/assets/images/invest/benefits/box.svg'), title: 'Trayectoria', description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.' },
          { icon: require('@/assets/images/invest/benefits/box.svg'), title: 'Conectividad', description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.' },
          { icon: require('@/assets/images/invest/benefits/box.svg'), title: 'Atención al cliente', description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.' },
          { icon: require('@/assets/images/invest/benefits/box.svg'), title: 'Confianza', description: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum.' },
        ],
        slider: [
          { url: require('@/assets/images/header/header-2.jpeg') },
          { url: require('@/assets/images/header/header-2.jpeg') },
          { url: require('@/assets/images/header/header-2.jpeg') },
        ]
      }
    },
    setLifeBenefits(state){
      state.lifeBenefits = [
        {
          title: 'Calidad de Vida',
          icon: require('@/assets/images/life/leaf.svg'),
          attributes: [
            { icon: require('@/assets/images/life/icons/calidad-de-vida/enchufe-emergencia.svg'), description: 'Una manera eficiente y confortable de acceder a un respaldo energético ante cortes de luz.' },
            { icon: require('@/assets/images/life/icons/calidad-de-vida/domotica.svg'), description: 'Poder controlar el consumo y uso eléctrico de tu hogar desde cualquier lugar, para brindarte seguridad y un comportamiento más sostenible de consumo.' },
            { icon: require('@/assets/images/life/icons/calidad-de-vida/aislacion-termica.svg'), description: 'A través de nuevas tecnologías en ventanas Termopanel logramos mantener de mejor forma la temperatura en tu departamento. Así consumimos menos energía y se hace más agradable estar en casa.' },
            { icon: require('@/assets/images/life/icons/calidad-de-vida/pet-spa.svg'), description: 'Una mascota puede mejorar tu calidad de vida, y nosotros nos preocupamos de mejorar la calidad de vida de tu mascota con una sala adecuada para su cuidado y limpieza.' },
            { icon: require('@/assets/images/life/icons/calidad-de-vida/bodegas-ecommerce.svg'), description: 'Un lugar donde dejar y mantener de forma segura todo lo que recibas por delivery cuando no estés.' },
          ]
        },
        {
          title: 'Ahorro día a día',
          icon: require('@/assets/images/life/leaf.svg'),
          attributes: [
            { icon: require('@/assets/images/life/icons/ahorro-dia-a-dia/ahorro-agua.svg'), description: 'Mediante el uso de aireadores en griferías, reducimos el consumo de agua sin que lo notes en tu uso diario.' },
            { icon: require('@/assets/images/life/icons/ahorro-dia-a-dia/ahorro-electrico.svg'), description: 'Contamos con iluminación LED y Full Electric, así podemos entregarte la mejor iluminación, en ahorro y sustentabilidad.' },
            { icon: require('@/assets/images/life/icons/ahorro-dia-a-dia/eficiencia.svg'), description: 'Un sistema que te brinda agua caliente cuando la necesitas, entregando así un servicio más económico y sustentable.' },
            { icon: require('@/assets/images/life/icons/ahorro-dia-a-dia/financiamiento-be.svg'), description: 'Certificado de Ecovivienda que califica el menor consumo y te permite acceder a tasas preferenciales de crédito hipotecario, en base a certificación de Banco Estado.' },
          ]
        },
        {
          title: 'Medioambiente',
          icon: require('@/assets/images/life/leaf.svg'),
          attributes: [
            { icon: require('@/assets/images/life/icons/medioambiente/estacionamientos-bicicletas.svg'), description: 'Facilitamos, hacemos más cómodo y potenciamos el uso del sistema de transporte menos contaminante.' },
            { icon: require('@/assets/images/life/icons/medioambiente/paneles-solares.svg'), description: 'Entregamos energía sostenible, que se traduce en un ahorro más consciente para la comunidad.' },
            { icon: require('@/assets/images/life/icons/medioambiente/estufas-electricas.svg'), description: 'El calor de hogar que te gusta sin uso de contaminantes.' },
            { icon: require('@/assets/images/life/icons/medioambiente/punto-limpio.svg'), description: 'Facilitamos tus pequeñas acciones sustentables, sin que tengas que salir del edificio.' },
            { icon: require('@/assets/images/life/icons/medioambiente/electromovilidad.svg'), description: 'Estamos preparados para darle energía a los vehículos eléctricos.' },
          ]
        },
      ]
    },
    setTrajectory(state){
      state.trajectory = {
        title: 'Trayectoria y respaldo',
        description: 'Nos hemos convertido en una de las constructoras e inmobiliarias más respetadas de Chile, con una vasta experiencia en construcciones. Nos encontramos en una constante preocupación para que todos los procesos y ámbitos de la organización den una eficiente, oportuna y responsable respuesta a nuestros clientes, colaboradores y personal interno, esforzándonos día a día por seguir construyendo y fortaleciendo la confianza en el tiempo. Queremos seguir siendo reconocidos por nuestras obras marcadas por la calidad en la construcción, responsable en las relaciones con sus clientes, comprometida con sus colaboradores y generadora de confianza.',
        subtitle: 'Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no suscipit quaerendum. At nam minimum ponderum. Est audiam animal molestiae te.',
        data: [
          { value: '+36', description: 'Años desarrollando & construyendo <br> proyectos inmobiliarios' },
          { value: '+75', description: 'Proyectos' },
          { value: 'US$ 2,5B', description: 'En desarrollos inmobiliarios' },
          { value: '+10.000', description: 'Unidades desarrolladas' },
          { value: '1,4 MM', description: 'M2 Desarrollados & construidos' },
        ]
      }
    },
    setNews(state){
      instance.get('/notices').then((response) => {
        state.news = response.data;
      })
    },
    setCampaigns(state){
      instance.get('/campaigns').then((response) => {
        state.campaigns = response.data;
      })
    }
  },
  actions: {
		setInitialData(context){
			context.commit('setSliders');
			context.commit('setProjects');
      context.commit('setInvestBenefits');
      context.commit('setLifeBenefits');
      context.commit('setTrajectory');
      context.commit('setUf');
		},
    setHeader(context, page){
      context.commit('setHeader', page);
    },
    setProjects(context){
      context.commit('setProjects');
    },
    setOpportunities(context){
      context.commit('setOpportunities')
    },
    setCampaigns(context){
      context.commit('setCampaigns')
    }
  },
  getters: {
    getProjects(state){
      return state.projects;
    },
    getOpportunities(state){
      return state.opportunities;
    },
    getProjectsByCommuneAndState: (state) => (commune, actual_state, region) => {
      let filtered = [];
      state.projects.map(project => {
        if(project.actual_state != 'Realizado'){
          if(region == "Todas" && actual_state != 'all'){
            if(project.actual_state == actual_state){
              filtered.push(project);
            }
          }
          else if(region == "Todas"){
            filtered.push(project);
          }
          else{
            if(commune == 'Todas' && actual_state == 'all'){
              if(region == project.region){
                filtered.push(project);
              }
            }
            else if(commune == 'Todas' && actual_state != 'all'){
              if(region == project.region){
                if(actual_state == project.actual_state){
                  filtered.push(project);
                }
              }
            }
            else if(commune != 'Todas' && actual_state != 'all'){
              if(commune == project.commune && actual_state == project.actual_state){
                filtered.push(project);
              }
            }
            else if(commune != 'Todas' && actual_state == 'all'){
              if(commune == project.commune){
                filtered.push(project);
              }
            }
          }
        }
      })
      return filtered;
    },
    getAccomplishedProjects(state){
      let filtered = [];
      state.projects.forEach(project => {
        if(project.actual_state == 'Realizado'){
          filtered.push(project);
        }
      })
      return filtered;
    },
    getProjectByMoby: (state) => (project_id) => {
			return (state.projects ? state.projects.find(project => project.project_id == project_id) : null );
		},
    getProjectByLink: (state) => (project_id) => {
			return (state.projects ? state.projects.find(project => project.link == project_id) : null );
		},
    getSliders(state){
      return state.sliders;
    },
    getHeader(state){
			return state.header;
		},
    getUf(state){
      return state.uf;
    },
    getInvestBenefits(state){
      return state.investBenefits;
    },
    getMenuItems(state){
      return state.menuItems;
    },
    getSidebarMenuItems(state){
      return state.sidebarMenuItems;
    },
    getLifeBenefits(state){
      return state.lifeBenefits;
    },
    getTrajectory(state){
      return state.trajectory;
    },
    getNews(state){
      return state.news
    },
    getArticleBySlug: (state) => (article_id) => {
      return (state.news ? state.news.find(article => article.slug == article_id) : null);
    },
    getCampaignBySlug: (state) => (campaign_id) => {
      return (state.campaigns ? state.campaigns.find(campaign => campaign.slug == campaign_id) : null);
    },
    getCampaigns(state){
      return state.campaigns
    }
  },
  modules: {
  }
})
