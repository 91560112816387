<template>
  <div class="grey-background py-3" :class="theme == 'light' ? 'light-background' : 'dark-background'">
    <div class="px-3">
      <div class="py-md-5 py-xl-5" style="border: 0.5px solid #54565A;">
        <b-container>
          <b-row class="my-4" :class="theme == 'light' ? 'text-black' : 'text-white'">
            <b-col cols="12" md="12" class="mt-auto" :class="windowSize < 1601 ? 'col-xl-12' : 'col-xl-2'">
              <h2 class="title" :class="theme == 'light' ? 'text-black' : 'text-white'">Encuentra tu proyecto</h2>
            </b-col>
            <b-col cols="12" md="3" xl="3" class="text-left mt-3 mt-md-0 mt-xl-0" v-if="regions">
              <p class="project-search-label mb-2">Región</p>
              <b-form-select v-if="regionSelected" v-model="regionSelected" @change="defaultCommuneSelected" size="lg">
                <option v-for="(region, key) in regions.filter(region => region.showRegion)" :key="'region' + key" :value="region">{{region.region}}</option>
              </b-form-select>
            </b-col>
            <b-col cols="12" xl="3" md="3" class="mt-3 mt-md-0 mt-xl-0" v-if="regions">
              <p class="project-search-label mb-2">Comuna</p>
              <b-form-select v-if="communeSelected" v-model="communeSelected" size="lg">
                <option v-for="(commune, key) in regionSelected.communesFiltered.length > 0 ? regionSelected.communesFiltered : regionSelected.communes" :key="'commune' + key" :value="commune">{{commune}}</option>
              </b-form-select>
            </b-col>
            <b-col cols="12" xl="3" md="3" class="mt-3 mt-md-0 mt-xl-0">
              <p class="project-search-label mb-2">Estado</p>
              <b-form-select v-model="stateSelected" size="lg">
                <option v-for="(state, key) in stateOptions" :key="'state' + key" :value="state.value">{{state.text}}</option>
              </b-form-select>
            </b-col>
            <b-col cols="12" md="3" class="mt-5 mt-md-auto mt-xl-auto" :class="windowSize < 1601 ? 'col-xl-3' : 'col-xl-1'">
              <b-button class="w-100 search-button" style="height:50px" :class="theme == 'light' ? 'btn-grey' : 'btn-white'" @click="search(), goTo('cards')">BUSCAR</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectSearch',
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    projects: {
      type: Array
    },
    showOnlyCommunesWithProjects: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      regionsLoaded: false,
      windowSize: window.innerWidth,
      regionSelected: null,
			communeSelected: null,
      stateSelected: 'all',
      stateOptions: [
        { value: 'all', text: 'Todos' },
        { value: 'Venta en Blanco', text: 'Venta en Blanco' },
        { value: 'Venta en Verde', text: 'Venta en Verde' },
        { value: 'Entrega Inmediata', text: 'Entrega Inmediata' },
      ],
      regions: [
        {
          region: "Todas",
          showRegion: true,
          communes: [
            "Todas"
          ],
          communesFiltered: [
            "Todas"
          ]
        },
        {
          region: "Región Metropolitana de Santiago",
          showRegion: false,
          communes: [
          "Todas",
          "Cerrillos",
          "Cerro Navia",
          "La Dehesa",
          "Conchalí",
          "El Bosque",
          "Estación Central",
          "Huechuraba",
          "Independencia",
          "La Cisterna",
          "La Florida",
          "La Granja",
          "La Pintana",
          "La Reina",
          "Las Condes",
          "Lo Barnechea",
          "Lo Espejo",
          "Lo Prado",
          "Macul",
          "Maipú",
          "Ñuñoa",
          "Pedro Aguirre Cerda",
          "Peñalolén",
          "Providencia",
          "Pudahuel",
          "Quilicura",
          "Quinta Normal",
          "Recoleta",
          "Renca",
          "Santiago",
          "San Joaquín",
          "San Miguel",
          "San Ramón",
          "Vitacura",
          "Puente Alto",
          "Pirque",
          "San José de Maipo",
          "Colina",
          "Lampa",
          "Tiltil",
          "San Bernardo",
          "Buin",
          "Calera de Tango",
          "Paine",
          "Melipilla",
          "Alhué",
          "Curacaví",
          "María Pinto",
          "San Pedro",
          "Talagante",
          "El Monte",
          "Isla de Maipo",
          "Padre Hurtado",
          "Peñaflor"
          ],
          communesFiltered: [
            "Todas"
          ]
        },
        {
          region: "Tarapacá",
          showRegion: false,
          communes: [
          "Todas",
          "Iquique",
          "Alto Hospicio",
          "Pozo Almonte",
          "Camiña",
          "Colchane",
          "Huara",
          "Pica"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Antofagasta",
          showRegion: false,
          communes: [
          "Todas",
          "Antofagasta",
          "Mejillones",
          "Sierra Gorda",
          "Taltal",
          "Calama",
          "Ollagüe",
          "San Pedro de Atacama",
          "Tocopilla",
          "María Elena"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Atacama",
          showRegion: false,
          communes: [
          "Todas",
          "Copiapó",
          "Caldera",
          "Tierra Amarilla",
          "Chañaral",
          "Diego de Almagro",
          "Vallenar",
          "Alto del Carmen",
          "Freirina",
          "Huasco"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Coquimbo",
          showRegion: false,
          communes: [
          "Todas",
          "La Serena",
          "Coquimbo",
          "Andacollo",
          "La Higuera",
          "Paiguano",
          "Vicuña",
          "Illapel",
          "Canela",
          "Los Vilos",
          "Salamanca",
          "Ovalle",
          "Combarbalá",
          "Monte Patria",
          "Punitaqui",
          "Río Hurtado"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "V Región",
          showRegion: false,
          communes: [
          "Todas",
          "V Región",
          "Casablanca",
          "Concón",
          "Juan Fernández",
          "Puchuncaví",
          "Quintero",
          "Viña del Mar",
          "Isla de Pascua",
          "Los Andes",
          "Calle Larga",
          "Rinconada",
          "San Esteban",
          "La Ligua",
          "Cabildo",
          "Papudo",
          "Petorca",
          "Zapallar",
          "Quillota",
          "Calera",
          "Hijuelas",
          "La Cruz",
          "Nogales",
          "San Antonio",
          "Algarrobo",
          "Cartagena",
          "El Quisco",
          "El Tabo",
          "Santo Domingo",
          "San Felipe",
          "Catemu",
          "Llaillay",
          "Panquehue",
          "Putaendo",
          "Santa María",
          "Quilpué",
          "Limache",
          "Olmué",
          "Villa Alemana"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región del Libertador Gral. Bernardo O’Higgins",
          showRegion: false,
          communes: [
          "Todas",
          "Rancagua",
          "Codegua",
          "Coinco",
          "Coltauco",
          "Doñihue",
          "Graneros",
          "Las Cabras",
          "Machalí",
          "Malloa",
          "San Francisco de Mostazal",
          "Olivar",
          "Peumo",
          "Pichidegua",
          "Quinta de Tilcoco",
          "Rengo",
          "Requínoa",
          "San Vicente de Tagua Tagua",
          "Pichilemu",
          "La Estrella",
          "Litueche",
          "Marchihue",
          "Navidad",
          "Paredones",
          "San Fernando",
          "Chépica",
          "Chimbarongo",
          "Lolol",
          "Nancagua",
          "Palmilla",
          "Peralillo",
          "Placilla",
          "Pumanque",
          "Santa Cruz"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región del Maule",
          showRegion: false,
          communes: [
          "Todas",
          "Talca",
          "Constitución",
          "Curepto",
          "Empedrado",
          "Maule",
          "Pelarco",
          "Pencahue",
          "Río Claro",
          "San Clemente",
          "San Rafael",
          "Cauquenes",
          "Chanco",
          "Pelluhue",
          "Curicó",
          "Hualañé",
          "Licantén",
          "Molina",
          "Rauco",
          "Romeral",
          "Sagrada Familia",
          "Teno",
          "Vichuquén",
          "Linares",
          "Colbún",
          "Longaví",
          "Parral",
          "Retiro",
          "San Javier de Loncomilla",
          "Villa Alegre",
          "Yerbas Buenas"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región del Biobío",
          showRegion: false,
          communes: [
          "Todas",
          "Concepción",
          "Coronel",
          "Chiguayante",
          "Florida",
          "Hualqui",
          "Lota",
          "Penco",
          "San Pedro de la Paz",
          "Santa Juana",
          "Talcahuano",
          "Tomé",
          "Hualpén",
          "Lebu",
          "Arauco",
          "Cañete",
          "Contulmo",
          "Curanilahue",
          "Los Álamos",
          "Tirúa",
          "Los Ángeles",
          "Antuco",
          "Cabrero",
          "Laja",
          "Mulchén",
          "Nacimiento",
          "Negrete",
          "Quilaco",
          "Quilleco",
          "San Rosendo",
          "Santa Bárbara",
          "Tucapel",
          "Yumbel",
          "Alto Biobío"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región de la Araucanía",
          showRegion: false,
          communes: [
          "Todas",
          "Temuco",
          "Carahue",
          "Cunco",
          "Curarrehue",
          "Freire",
          "Galvarino",
          "Gorbea",
          "Lautaro",
          "Loncoche",
          "Melipeuco",
          "Nueva Imperial",
          "Padre las Casas",
          "Perquenco",
          "Pitrufquén",
          "Pucón",
          "Saavedra",
          "Teodoro Schmidt",
          "Toltén",
          "Vilcún",
          "Villarrica",
          "Cholchol",
          "Angol",
          "Collipulli",
          "Curacautín",
          "Ercilla",
          "Lonquimay",
          "Los Sauces",
          "Lumaco",
          "Purén",
          "Renaico",
          "Traiguén",
          "Victoria"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región de Los Ríos",
          showRegion: false,
          communes: [
          "Todas",
          "Valdivia",
          "Corral",
          "Lanco",
          "Los Lagos",
          "Máfil",
          "Mariquina",
          "Paillaco",
          "Panguipulli",
          "La Unión",
          "Futrono",
          "Lago Ranco",
          "Río Bueno"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región de Los Lagos",
          communes: [
          "Todas",
          "Puerto Montt",
          "Calbuco",
          "Cochamó",
          "Fresia",
          "Frutillar",
          "Los Muermos",
          "Llanquihue",
          "Maullín",
          "Puerto Varas",
          "Castro",
          "Ancud",
          "Chonchi",
          "Curaco de Vélez",
          "Dalcahue",
          "Puqueldón",
          "Queilén",
          "Quellón",
          "Quemchi",
          "Quinchao",
          "Osorno",
          "Puerto Octay",
          "Purranque",
          "Puyehue",
          "Río Negro",
          "San Juan de la Costa",
          "San Pablo",
          "Chaitén",
          "Futaleufú",
          "Hualaihué",
          "Palena"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región Aisén del Gral. Carlos Ibáñez del Campo",
          showRegion: false,
          communes: [
          "Todas",
          "Coihaique",
          "Lago Verde",
          "Aisén",
          "Cisnes",
          "Guaitecas",
          "Cochrane",
          "O’Higgins",
          "Tortel",
          "Chile Chico",
          "Río Ibáñez"
          ],
          communesFiltered: [
            "Todas",
          ]
        },
        {
          region: "Región de Magallanes y de la Antártica Chilena",
          communes: [
          "Todas",
          "Punta Arenas",
          "Laguna Blanca",
          "Río Verde",
          "San Gregorio",
          "Cabo de Hornos (Ex Navarino)",
          "Antártica",
          "Porvenir",
          "Primavera",
          "Timaukel",
          "Natales",
          "Torres del Paine"
          ],
          communesFiltered: [
            "Todas",
          ]
        },

        {
          region: "Arica y Parinacota",
          showRegion: false,
          communes: ["Todas", "Arica", "Camarones", "Putre", "General Lagos"],
          communesFiltered: [
            "Todas",
          ]
        },

        {
          region: "Región de Ñuble",
          showRegion: false,
          communes: [
          "Todas",
          "Cobquecura",
          "Coelemu",
          "Ninhue",
          "Portezuelo",
          "Quirihue",
          "Ránquil",
          "Treguaco",
          "Bulnes",
          "Chillán Viejo",
          "Chillán",
          "El Carmen",
          "Pemuco",
          "Pinto",
          "Quillón",
          "San Ignacio",
          "Yungay",
          "Coihueco",
          "Ñiquén",
          "San Carlos",
          "San Fabián",
          "San Nicolás"
          ],
          communesFiltered: [
            "Todas",
          ]
        }
      ]
    }
  },
  mounted(){
    this.showActiveRegions();
    this.windowSize = window.innerWidth;
  },
  methods: {
    defaultRegionSelected(){
      this.regionSelected = this.regions[0];
      this.communeSelected = this.regionSelected.communesFiltered.length > 0 ? this.regionSelected.communesFiltered[0] : this.regionSelected.communes[0];
    },
    defaultCommuneSelected(){
      this.communeSelected = this.regionSelected.communesFiltered.length > 0 ? this.regionSelected.communesFiltered[0] : this.regionSelected.communes[0];
      if(this.showOnlyCommunesWithProjects){
        this.showCommunesWithProjects();
      }
    },
    search() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'search-test'
      });
      console.log('test-gtm')
      this.$emit('filters', this.communeSelected, this.stateSelected, this.regionSelected.region);
    },
    goTo(refName) {
      this.$emit('goTo', refName)
    },
    showActiveRegions(){
      if(this.projects){
        this.projects.map(project => {
        this.regions.map(region => {
          if(project.region == region.region){
            region.showRegion = true;
          }
        })
      })
      this.regionsLoaded = true;
      }
    },
    showCommunesWithProjects(){
      if(this.showOnlyCommunesWithProjects){
        this.regionSelected.communes.forEach(commune => {
          this.projects.forEach(project => {
            if(project.actual_state  != 'Realizado'){
              if(commune.toLowerCase() == project.commune.toLowerCase() && !this.regionSelected.communesFiltered.includes(commune)){
                this.regionSelected.communesFiltered.push(project.commune);
              }
            }
          })
        })
        this.communeSelected = this.regionSelected.communesFiltered.length > 0 ? this.regionSelected.communesFiltered[0] : this.regionSelected.communes[0];
      }
    },
    onResize() {
      this.windowSize = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    'projects': {
			handler: function()  {
        this.showActiveRegions();
			}
		},
    'regionsLoaded': {
      handler: function() {
        this.defaultRegionSelected();
        this.showCommunesWithProjects();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.project-search-label{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.search-button{
  font-family: Montserrat;
  font-size:15px
}
.btn-white:hover{
  color:#ffffff!important;
}
</style>
