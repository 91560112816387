import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Fortaleza' }
  },
  {
    path: '/nosotros',
    props: true,
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/servicio-al-cliente',
    props: true,
    name: 'CustomerService',
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomerService.vue'),
    meta: { title: 'Fortaleza - Servicio al cliente' }
  },
  {
		path: '/proyecto/:project_id',
		props: true,
		name: 'ProjectDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectDetail.vue'),
    meta: { title: 'Fortaleza' }
	},
  {
		path: '/proyectos-venta/edificio-vista-golf-ii/',
    redirect: '/proyecto/vista-golf',
		props: true,
		name: 'ProjectDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectDetail.vue'),
    meta: { title: 'Fortaleza' }
	},
  {
		path: '/proyectos',
		props: true,
		name: 'ProjectList',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectList.vue'),
    meta: { title: 'Fortaleza - Proyectos' }
	},
  {
		path: '/proyectos-realizados',
		props: true,
		name: 'AccomplishedProjects',
    component: () => import(/* webpackChunkName: "about" */ '../views/AccomplishedProjects.vue'),
    meta: { title: 'Fortaleza - Proyectos Realizados' }
	},
  {
		path: '/invierte-con-nosotros',
		props: true,
		name: 'Invest',
    component: () => import(/* webpackChunkName: "about" */ '../views/Invest.vue'),
    meta: { title: 'Fortaleza - Invierte con nosotros' }
	},
  {
		path: '/life',
		props: true,
		name: 'Life',
    component: () => import(/* webpackChunkName: "about" */ '../views/Life.vue'),
    meta: { title: 'Fortaleza - Life' }
	},
  {
		path: '/blog',
		props: true,
		name: 'Blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue'),
    meta: { title: 'Fortaleza - Blog' }
	},
  {
		path: '/blog/:article_id',
		props: true,
		name: 'Article',
    component: () => import(/* webpackChunkName: "about" */ '../views/Article.vue'),
    meta: { title: 'Fortaleza - Blog' }
	},
  {
		path: '/campana/:campaign_id',
		props: true,
		name: 'Campaign',
    component: () => import(/* webpackChunkName: "about" */ '../views/Campaign.vue'),
    meta: { title: 'Fortaleza - Campaña' }
	},
  {
		path: '/oportunidades',
		props: true,
		name: 'Opportunities',
    component: () => import(/* webpackChunkName: "about" */ '../views/Opportunities.vue'),
    meta: { title: 'Fortaleza - Oportunidades' }
	},
  {
		path: '/beneficios',
		props: true,
		name: 'Campaigns',
    component: () => import(/* webpackChunkName: "about" */ '../views/Campaigns.vue'),
    meta: { title: 'Fortaleza - Beneficios' }
	},
  {
		path: '/referidos',
		props: true,
		name: 'Referrals',
    component: () => import(/* webpackChunkName: "about" */ '../views/Referral.vue'),
    meta: { title: 'Fortaleza - Referidos' }
	},
  {
    path: '*',
    name: 'Home',
    component: Home,
    meta: { title: 'Fortaleza' }
  },


]

const router = new VueRouter({
  mode: 'history',
  routes
})

const DEFAULT_TITLE = 'Fortaleza';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router
