<template>
  <div ref="cards" class="py-5" :class="{'green-background' : windowSize > 992}">
    <b-container>
      <div>
        <h3 class="featured-projects-title">Proyectos <br> destacados</h3>
        <b-row class="mt-3">
          <b-col cols="2" md="auto" xl="auto" class="my-auto">
            <b-img-lazy v-bind="lazyOptions" :src="require('@/assets/images/projects/features/small-logo.svg')"></b-img-lazy>
          </b-col>
          <b-col cols="10" md="5" xl="5" class="my-auto">
            <p class="featured-projects-subtitle mb-0">Con más de <b>4.500</b> construcciones que representan <b>550.000 m2</b>. <br> Hoy te invitamos a conocer nuestros 7 proyectos activos.</p>
          </b-col>
        </b-row>
      </div>
      <b-row class="pt-5" v-if="projects.length > 0">
        <b-col cols="12" md="6" xl="6" v-for="(project, key) in projects.slice(0,2)" :key="'project' + key" :class="{'pt-3 pt-md-0 pt-xl-0' : key > 0}">
          <router-link v-if="project.actual_state != 'Realizado'" :to="{ name: 'ProjectDetail', params: { project_id: project.link }}">
            <b-row>
              <b-col cols="7" xl="7" md="6" class="pr-2" style="position: relative;">
                <div class="main-image background-image" xl="6" :style="{ backgroundImage: 'url(' + project.featured_project_main_image_url + ')' }"></div>
                <!-- <div class="text-left px-3" style="position:absolute;bottom:5%;width:85%" :style="`color: ${project.featured_text_color}`">
                  <h3 class="featured-project-title text-left">{{project.name}}</h3>
                  <p class="featured-project-subtitle text-left my-2">{{project.description | truncate(50, '...')}}</p>
                  <router-link :to="{ name: 'ProjectDetail', params: { project_id: project.link }}"><p class="featured-project-know-project text-left">Conocer proyecto</p></router-link>
                </div> -->
              </b-col>
              <b-col cols="5" xl="5" md="6" class="pl-2">
                <b-row class="h-100">
                  <b-col xl="12" class="pb-2">
                    <div class="secondary-image background-image" xl="6" :style="{ backgroundImage: 'url(' + project.featured_project_second_image_url + ')' }"></div>
                  </b-col>
                  <b-col xl="12" class="pt-2">
                    <div class="secondary-image background-image" xl="6" :style="{ backgroundImage: 'url(' + project.featured_project_third_image_url + ')' }"></div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12">
                <b-row>
                  <b-col cols="12" md="auto" xl="auto" class="text-left my-auto">
                    <h3 class="featured-project-title mb-0">{{project.featured_project_title}}</h3>
                  </b-col>
                  <b-col cols="12" md="auto" xl="auto" class="text-left my-auto ml-auto">
                    <p v-if="project.price" class="featured-project-title mb-0 mt-2 mt-md-0 mt-xl-0"><b-img class="pr-2" :src="require('@/assets/images/projects/features/green-square.svg')"></b-img>Desde UF <b>{{project.price | numberFormat}}</b></p>
                  </b-col>
                  <b-col cols="12" md="auto" xl="auto" class="text-left my-auto">
                    <b-row>
                      <b-col cols="4" md="auto" xl="auto" class="text-left mt-2 mt-md-0 mt-xl-0">
                        <p class="featured-project-title featured-project-bedrooms mb-0" v-if="project.project_bedrooms.length > 0">
                          <template v-for="(bedroom, key) in project.project_bedrooms">
                            <span :key="'bedroom' + key">{{bedroom.number}}
                              <template v-if="project.project_bedrooms.length == 2">
                                <span v-if="key == Object.keys(project.project_bedrooms).length - 2"> y </span>
                              </template>
                              <template v-if="project.project_bedrooms.length > 2">
                                <span v-if="key != Object.keys(project.project_bedrooms).length - 1 && key != Object.keys(project.project_bedrooms).length - 2">,</span>
                                <span v-if="key == Object.keys(project.project_bedrooms).length - 2"> y </span>
                              </template>
                            </span>
                          </template>
                          <b-img class="pl-2 project-info-icon" :src="require('@/assets/images/projects/features/bed.svg')"></b-img>
                        </p>
                      </b-col>
                      <b-col cols="4" md="auto" xl="auto" class="text-center mt-2 mt-md-0 mt-xl-0">
                        <p class="featured-project-title featured-project-bathrooms mb-0" v-if="project.project_bathrooms.length > 0">
                          <template v-for="(bathroom, key) in project.project_bathrooms">
                            <span :key="'bedroom' + key">{{bathroom.number}}
                              <template v-if="project.project_bathrooms.length == 2">
                                <span v-if="key == Object.keys(project.project_bathrooms).length - 2"> y </span>
                              </template>
                              <template v-if="project.project_bathrooms.length > 2">
                                <span v-if="key != Object.keys(project.project_bathrooms).length - 1 && key != Object.keys(project.project_bathrooms).length - 2">,</span>
                                <span v-if="key == Object.keys(project.project_bathrooms).length - 2"> y </span>
                              </template>
                            </span>
                          </template>
                          <b-img class="pl-2 project-info-icon" :src="require('@/assets/images/projects/features/bath.svg')"></b-img>
                        </p>
                      </b-col>
                      <b-col cols="4" md="auto" xl="auto" class="text-right mt-2 mt-md-0 mt-xl-0">
                        <p class="featured-project-title mb-0" v-if="project.project_living_rooms.length > 0">
                          <template v-for="(room, key) in project.project_living_rooms">
                            <span :key="'bedroom' + key">{{room.number}}
                              <template v-if="project.project_living_rooms.length == 2">
                                <span v-if="key == Object.keys(project.project_living_rooms).length - 2"> y </span>
                              </template>
                              <template v-if="project.project_living_rooms.length > 2">
                                <span v-if="key != Object.keys(project.project_living_rooms).length - 1 && key != Object.keys(project.project_living_rooms).length - 2">,</span>
                                <span v-if="key == Object.keys(project.project_living_rooms).length - 2"> y </span>
                              </template>
                            </span>
                          </template>
                          <b-img class="pl-2 project-info-icon" :src="require('@/assets/images/projects/features/room.svg')"></b-img>
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <div class="project-info"></div>
              </b-col>
            </b-row>
          </router-link>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <p class="not-found bolder text-center">No se encontró ningún proyecto con esos filtros</p>
        </b-col>
      </b-row>
      <b-row class="mx-auto mt-5 mb-3 text-center">
        <b-col cols="12" class="px-0 text-center">
          <router-link :to="'/proyectos'"><b-button class="view-all-projects mx-auto" variant="grey-border">Conocer todos los proyectos</b-button></router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  // create variable named Test
  name: 'FeaturedProjects',
  props: {
    communeSelected: {
      type: String,
      default: "Todas"
    },
    stateSelected: {
      type: String,
      default: 'all'
    },
    regionSelected: {
      type: String,
      default: "Todas"
    },
  },
  data() {
    return {
      loading: true,
      lazyOptions: {
        blank: true,
        blankColor: '#bbb',
        fluidGrow: true,
      }
    }
  },
  computed: {
    projects() {
      return this.$store.getters.getProjectsByCommuneAndState(this.communeSelected, this.stateSelected, this.regionSelected);
    },
    windowSize(){
      return window.innerWidth;
    }
  },
  watch: {
    projects(newValue) {
      if(newValue){
        if(this.projects != null){
          this.loading = false;
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.featured-project-title{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.featured-project-know-project{
  text-decoration: underline;
  text-underline-position: under;
}
.main-image{
  height:600px;
}
.secondary-image{
  height:100%
}

.view-all-projects{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  letter-spacing: 0.3px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
  padding: 15px;
}
@media (max-width:1600px){
  .main-image{
    height:400px;
  }
}
@media (max-width:992px){
  .view-all-projects{
    width:100%
  }
  .main-image{
    height:300px;
  }
  .featured-project-bathrooms{
    font-size:12px;
  }
  .featured-project-bedrooms{
    font-size:12px;
  }
}
.not-found{
  font-size:40px;
  padding:50px 0px 0px 0px;
  line-height:100%
}
.green-background{
  background-image: url('../../assets/images/projects/features/background.jpg');
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}
.featured-projects-title{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 31px;
  line-height: 36px;
  /* or 116% */

  letter-spacing: 0.2px;
  text-transform: uppercase;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;

}
.featured-projects-subtitle{
  font-family: Neutra Text TF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* or 137% */


  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.project-info{
  border-bottom: 1px solid #8BCB77;
  width:100%;
  height:2px;
  margin-top:5px
}
.project-info-icon{
  width:40px;
  height:40px
}
a{
  text-decoration: none!important;
}
</style>
