<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      v-if="sliders"
    >
      <!-- Mobile Sliders -->
      <template
        class="d-block d-sm-none d-md-none"
        v-for="(slide, key) in sliders"
      >
        <a
          v-if="
            windowSize < 450 &&
            slide.mobile_image_url != '/mobile_images/original/missing.png'
          "
          :href="slide.link"
          :target="slide.open_link_in_another_tab ? '_blank' : null"
          :key="'slide' + key"
        >
          <div class="carousel-item">
            <b-img class="w-100 h-100" :src="slide.mobile_image_url"></b-img>
          </div>
        </a>
      </template>
      <!-- Desktop Sliders-->
      <template
        class="d-none d-sm-block d-md-block"
        v-for="(slide, key) in sliders"
      >
        <a
          v-if="
            windowSize > 450 &&
            slide.image_url != '/images/original/missing.png'
          "
          :href="slide.link"
          :target="slide.open_link_in_another_tab ? '_blank' : null"
          :key="'slide' + key"
        >
          <div class="carousel-item">
            <b-img class="w-100 h-100" :src="slide.image_url"></b-img>
          </div>
        </a>
      </template>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "HeaderSlider",
  props: {
    sliders: { type: Array },
  },
  data() {
    return {
      slide: null,
      windowSize: window.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.windowSize = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-title {
  font-weight: 700;
  line-height: 56px;
}
.carousel-item {
  background-color: #e6e7e8 !important;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

// Mobile
@media (max-width: 450px) {
  .carousel-item {
    height: auto;
  }
  .header-title {
    font-size: 25px;
    line-height: 36px;
  }
}
</style>
