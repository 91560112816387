<template>
  <div class="home">
    <h1 class="d-none">Inmobiliaria Fortaleza</h1>
    <Navbar v-if="sliders" />
    <HeaderSlider v-if="sliders" :sliders="sliders"/>
    <ProjectSearch v-if="sliders" theme="light" :projects="projects" @filters="filterAction" @goTo="goToAction" :showOnlyCommunesWithProjects="true"/>
    <b-overlay :show="!projects" rounded="sm" :style="!projects ? 'height:1050px' : null" :class="{'green-background' : windowSize > 992}">
      <FeaturedProjects v-if="projects && sliders" ref="featuredProjects" :projects="projects" :communeSelected="communeSelected" :stateSelected="stateSelected" :regionSelected="regionSelected"/>
    </b-overlay>
    <Contact v-if="sliders" background="grey-background" ref="contact"/>
    <Footer v-if="sliders" @goTo="goToContact" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import HeaderSlider from '@/components/HeaderSlider.vue'
import ProjectSearch from '@/components/ProjectSearch.vue'
import FeaturedProjects from '@/components/Home/FeaturedProjects.vue'
import Contact from '@/components/Contact.vue'

export default {
  name: 'Home',
  components: {
    HeaderSlider,
    ProjectSearch,
    FeaturedProjects,
    Contact,
    Navbar,
    Footer
  },
  data() {
    return {
      communeSelected: 'Todas',
      stateSelected: 'all',
      regionSelected: 'Todas'
    }
  },
  methods: {
    filterAction(commune, state, region) {
      this.communeSelected = commune;
      this.stateSelected = state;
      this.regionSelected = region;
    },
    goToAction(destiny){
      var element = this.$refs['featuredProjects'].$refs[destiny];
      var headerOffset = 0;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
    goToContact(destiny){
      var element = this.$refs[destiny].$refs['scroll'];
      var headerOffset = 0;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted () {
    window.scrollTo(0,0);
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
    sliders() {
      return this.$store.getters.getSliders;
    },
    windowSize(){
      return window.innerWidth;
    }
  },
}
</script>

<style scoped lang="scss">
.green-background{
  background-image: url('../assets/images/projects/features/background.jpg');
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}
.featured-projects-overlay{
  height:1050px
}
@media (max-width:992px){
  .featured-projects-overlay{
    height:1300px
  }
}
</style>
